import * as React from 'react'
import AtButton from '../../../../ui/button'
import './style'

interface IProps {
  className?: string
}

const About: React.FC<IProps> = () => {
  return (
    <div className="com-about-us-about">
      <h2 className="title">关于凹凸</h2>
      <div className="panel-about">
        <div className="l">
          <h3>万款车型共享平台-覆盖市场90%以上新能源车型</h3>
          <p>
            凹凸出行创始于2013年，隶属于乐享似锦（浙江）信息科技有限公司，是一家全新共享模式租车平台，凹凸出行率先将“车共享”理念带入中国。通过凹凸建立的安全保障平台，车主安心将自己的闲置车辆租给他人使用；租客能够用更经济的价格使用车况更好、车型选择上更丰富的车辆。
          </p>
        </div>
        <img src={require('./images/about-1.jpg')} />
      </div>

      <h2 className="title">核心优势</h2>
      <div className="panel-superiority">
        <div className="item">
          <img src={require('./images/icon-1.png')} />
          <h3>车型丰富，社区有趣</h3>
          <p>
            个性车型每周上新
            <br />
            APP社区内容，精彩纷呈
          </p>
        </div>

        <div className="item">
          <img src={require('./images/icon-2.png')} />
          <h3>3步下单，上门取送</h3>
          <p>
            线上选车，高效便捷
            <br />
            车管家上门取送车
          </p>
        </div>

        <div className="item">
          <img src={require('./images/icon-3.png')} />
          <h3>全面保障，用车无忧</h3>
          <p>
            7X24小时道路救援
            <br />
            365天人工客服在线
            <br />
            高额度全方位保障
          </p>
        </div>

        <div className="item">
          <img src={require('./images/icon-4.png')} />
          <h3>经济划算，押金双免</h3>
          <p>
            车辆租金低于其他品牌
            <br />
            长租更优惠“省下钱，
            <br />
            就是赚到钱”
          </p>
        </div>
      </div>

      <h2 className="title">对比传统租车</h2>
      <div className="panel-contrast">
        <img src={require('./images/about-3.jpg')} />
      </div>

      <h2 className="title">我们的服务</h2>
      <div className="panel-service">
        <div className="sub sub-1">
          <h3>一键下单，乐享出行</h3>
          <p>
            凭有效身份证、驾驶证，在官网上注册并认证，万款车型，任君挑选。下载凹凸出行APP选择车主共享的爱车，一键下单，更有车管家上门取送车。
          </p>
          <AtButton
            className="btn"
            size="small"
            onClick={() => {
              window.location.href =
                '/signup?utm_source=SEO&utm_medium=SEO&utm_campaign=SEO&utm_term=aa84ec947f0a72b161a8d27598eda21e'
            }}
          >
            立即租车
          </AtButton>
        </div>
        <div className="sub sub-2">
          <h3>出租车辆，坐享收益</h3>
          <p>
            车辆行驶里程未超过20万公里，手续及保障齐全，车况良好并有闲置时间，便可上传至凹凸出行平台，分享给有用车需求的朋友。车主租客互相选择，成单高效。
          </p>
          <AtButton
            className="btn"
            size="small"
            theme="blue"
            onClick={() => {
              window.location.href = '/sh/carOwner'
            }}
          >
            成为车主
          </AtButton>
        </div>
      </div>

      <h2 className="title">我们的愿景</h2>
      <div className="panel-hope">
        <p>
          凹凸出行乐意看到互惠互利的景象。即租客能减少租车费用享受更好的用车体验，出租方又能利用闲置资源促进经济的提升。但是凹凸出行更乐意看到因为车辆的共享，减少交通拥堵情况，更能减少碳排放对空气污染的影响，让环境变得更加美好。
        </p>
        <img src={require('./images/bg-hope.jpg')} />
      </div>

      <h2 className="title">我们在前行</h2>
      <div className="panel-runing">
        <p>
          平台订单总共享里程超过25亿公里，相当于绕地球6239圈；总共减少碳排放313吨。
          <br />
          从2014年5月APP上线以来，凹凸出行已累计：
        </p>

        <div className="table">
          <div className="item">
            <strong>10000+</strong>
            <p>精选共享车型</p>
          </div>
          <div className="item">
            <strong>300000+</strong>
            <p>个人共享车辆</p>
          </div>
          <div className="item">
            <strong>20000000+</strong>
            <p>平台注册用户</p>
          </div>
          <div className="item">
            <strong>300</strong>
            <p>主流城市共享服务</p>
          </div>
        </div>
      </div>

      <h2 className="title">我们的目标</h2>
      <div className="panel-objective">
        <div className="l">
          <h3>拥抱客户 简单靠谱</h3>
          <p>
            通过车共享，增进人和人之间的联系，降低双方的用车成本并优化资源，同时能实现科技改变社会的宏观目标。凹凸出行从点滴做起，改善道路交通压力，减轻地球环境的负荷，一起携手共享更美好的世界。
          </p>
        </div>
        <img src={require('./images/about-2.jpg')} />
      </div>
    </div>
  )
}

export default About
