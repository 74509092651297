import * as React from 'react'
import Helmet from 'react-helmet'
import Pager from 'ui/pager'
import RegisterModal from '../../components/registerModal'
import RegisterSuccessModal from '../../components/registerSuccessModal'
import AsideCities from './components/asideCities'
import AsideNews from './components/asideNews'
import AsidePrice from './components/asidePrice'
import PriceList from './components/priceList'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderAside() {
    return (
      <div className="aside">
        <AsidePrice
          allCity={this.props.allCity}
          city={this.props.city}
          showCityMore={this.state.showCityMore}
          onCityMoreClick={this.onCityMoreClick}
        />
        <AsideNews list={this.props.newsList.list} city={this.props.city} />
        <AsideCities allCity={this.props.allCity} />
      </div>
    )
  }

  renderMain() {
    return (
      <div className="mainbody">
        <div className="header">
          <h1>{this.props.city.code === '000000' ? '' : this.props.city.name}最新租车价格表</h1>
          <h2
            onClick={() => {
              this.setState({ registerVisible: true })
            }}
          >
            领取4555元租车优惠券（含月租券）
          </h2>
          <div className="router">
            <a href="/">首页</a>
            <span>·</span>
            <a
              href={this.props.city.code === '000000' ? '/price' : '/price/' + this.props.city.code}
            >
              租车价格表
            </a>
          </div>
        </div>

        <PriceList
          list={this.props.carList}
          city={this.props.city}
          onBookClick={() => {
            this.setState({ registerVisible: true })
          }}
        />

        <div className="pager">
          {Math.ceil(this.props.carListCount / this.props.carListSize) > 1 && (
            <Pager
              href={v =>
                this.props.city.code === '000000'
                  ? '/price?page=' + v
                  : `/price/${this.props.city.code}?page=${v}`
              }
              current={this.props.carListNum}
              total={Math.ceil(this.props.carListCount / this.props.carListSize)}
            />
          )}
        </div>
      </div>
    )
  }

  renderHeader() {
    const city = this.props.city
    if (city.code === '000000') {
      return (
        <Helmet>
          <title>租车一天多少钱_2023最新租车价格_租车费用明细-凹凸租车</title>
          <meta
            name="description"
            content="租车一天大概多少钱？凹凸租车提供2023最新租车价格、租车价格明细表、租车报价明细，分车型了解不同租车报价；凹凸租车万款车型随心租！"
          />
          <meta name="keywords" content="租车一天多少钱,租车价格" />
          <meta
            name="mobile-agent"
            content="format=html5;url=https://m.atzuche.com/m/index/price"
          />
        </Helmet>
      )
    }

    return (
      <Helmet>
        <title>{`${city.name}租车价格_${city.name}租车多少钱_2023最新${city.name}租车报价-凹凸租车`}</title>
        <meta
          name="description"
          content={`凹凸租车提供2023最新${city.name}租车价格、${city.name}租车报价明细表；带你了解${city.name}租车多少钱一天？分车型了解不同租车报价信息等；凹凸租车万款车型随心租！`}
        />
        <meta name="keywords" content={`${city.name}租车价格,${city.name}租车多少钱`} />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/m/index/price/${city.code}`}
        />
      </Helmet>
    )
  }

  render() {
    return (
      <div className="page-price">
        {this.renderHeader()}

        {!this.state.registerSuccess && (
          <div className="main">
            {this.renderAside()}
            {this.renderMain()}
          </div>
        )}

        <RegisterModal
          visible={this.state.registerVisible}
          onClose={() => {
            this.setState({ registerVisible: false })
          }}
          onRegisterOk={() => {
            this.setState({ registerVisible: false, registerSuccess: true })
          }}
        />

        <RegisterSuccessModal
          visible={this.state.registerSuccess}
          onClose={() => {
            this.setState({ registerSuccess: false })
          }}
        />
      </div>
    )
  }
}
