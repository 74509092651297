import * as React from 'react'
import './style'

interface IProps {
  visible: boolean
  onClose: () => void
}

const RegisterSuccessModal: React.FC<IProps> = props => {
  if (!props.visible) {
    return null
  }

  return (
    <div className="com-register-success-modal">
      <div className="content">
        <h1>
          注册成功
          <br />
          4555元大礼包已到账
        </h1>

        <img src="https://cdn.atzuche.com/pc/qr/app.png" className="qr" />

        <h2>扫码下载APP，开启快乐租车之旅！</h2>

        <a href="/" className="gohome">
          回到首页
        </a>

        <div className="links">
          <a href="http://localhost:20004/about/privacyPolicy">隐私协议</a>
          <a href="http://localhost:20004/about/vipServiceWeb">服务协议</a>
        </div>

        <small>
          开发者名称：乐享似锦（浙江）信息科技有限公司
          <br />
          更新时间：2021.07.06 版本：6.5.2
        </small>

        <button className="close" onClick={props.onClose} />
      </div>
    </div>
  )
}

export default RegisterSuccessModal
